<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body>
                <b-row class="mb-0">
                    <b-col cols="2" class="borderRight pr-0">
                        <b-card-body>
                            <b-card-title>追踪溯源</b-card-title>
                            <flat-pickr
                                v-model="filterValues.time"
                                placeholder="时间"
                                :config="{
                                    time_24hr: true,
                                    mode: 'range',
                                    locale: $i18n.locale === 'cn' ? 'zh' : 'en',
                                }"
                                class="form-control"
                            />
                            <v-select
                                v-model="filterValues.system"
                                :options="filterOptions.System"
                                placeholder="系统"
                                class="w-100 mt-1"
                            />
                            <v-select
                                :inputmode="false"
                                v-model="filterValues.eqname"
                                :options="filterOptions.Eqname"
                                placeholder="设备"
                                class="w-100 mt-1"
                            />
                            <v-select
                                v-model="filterValues.degree"
                                :options="filterOptions.Degree"
                                placeholder="等级"
                                class="w-100 mt-1"
                            />
                            <v-select
                                v-model="filterValues.secondary_class"
                                :options="filterOptions.Secondary"
                                placeholder="类型"
                                class="w-100 mt-1"
                            />
                        </b-card-body>
                        <hr class="my-0" />
                        <vue-perfect-scrollbar
                            :suppressScrollX="true"
                            style="height: calc(100vh - 524px)"
                        >
                            <div
                                v-if="!myDataArray.length && !loading"
                                style="
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                "
                                class="text-center"
                            >
                                <div>未查询到数据</div>
                                <b-icon-exclamation-triangle-fill
                                    scale="5"
                                    class="my-3"
                                />
                                <div class="text-muted">No items found</div>
                            </div>

                            <div
                                v-if="loading"
                                style="
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);
                                "
                                class="text-center"
                            >
                                <div>努力加载中...</div>
                                <b-spinner
                                    label="Loading..."
                                    style="width: 4rem; height: 4rem"
                                    class="my-2"
                                    variant="primary"
                                />
                                <div class="text-muted">Loading ...</div>
                            </div>
                            <b-list-group
                                v-show="!loading"
                                flush
                                class="listGroupSelect"
                            >
                                <b-list-group-item
                                    v-for="(item, index) of myDataArray"
                                    @click="handleNewDeviceParams(item)"
                                    :key="index"
                                    :style="
                                        selectFaultId === item.id
                                            ? `border-left: 3px solid #1890ff;`
                                            : ''
                                    "
                                >
                                    <span
                                        class="bullet bullet-sm mr-1"
                                        :class="
                                            'bullet-' +
                                            getDegreeColor(item.degree)
                                        "
                                    ></span
                                    >{{ item.fault_name }}
                                </b-list-group-item>
                            </b-list-group>
                        </vue-perfect-scrollbar>

                        <b-card-footer
                            class="pt-1 d-flex align-items-center justify-content-between text-muted"
                        >
                            <span>共扫描到</span>
                            <b-badge pill variant="light-primary"
                                >{{ myDataArray.length }}
                            </b-badge>
                            <span>风险漏洞</span>
                        </b-card-footer>
                    </b-col>
                    <b-col cols="10" class="pl-0">
                        <device-table
                            :allDevices="allDevices"
                            ref="deviceTableInstance"
                        ></device-table>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import Ripple from "vue-ripple-directive";
import PageTitle from "@/views/layout/PageTitle.vue";
import vSelect from "vue-select";

import flatPickr from "vue-flatpickr-component";
import zh from "flatpickr/dist/l10n/zh.js";
import { momentJs } from "@/libs/utils/moment";

import JsonExcel from "vue-json-excel";
import { reactive, ref, toRefs, watch } from "@vue/composition-api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import DeviceTable from "../../../common/agent-list/components/DeviceTable.vue";
import {
    detail_get_results,
    getDiagnosisAllDevices,
    getDiagnosisDetailSearchOptions,
    getDiagnosisTraceabilityGetDeviceInfo,
} from "@/api/diagnosis";

export default {
    name: "AgentAnalyse",
    directives: {
        Ripple,
    },
    data() {
        return {
            allDevices: [],
            selectFaultId: null,
        };
    },
    components: {
        PageTitle,
        vSelect,
        flatPickr,
        downloadExcel: JsonExcel,
        VuePerfectScrollbar,
        DeviceTable,
    },
    props: ["hide-title"],
    setup() {
        const deviceTableInstance = ref(null);

        const event = reactive({
            loading: false,
            filterValues: {
                time: null,
                degree: null,
                secondary_class: null,
                eqname: "",
                system: null,
            },
            filterOptions: {
                Type: [],
                Degree: [],
                Secondary: [],
                System: [],
                Eqname: [],
            },
            projectOperation: [],
            detail: {
                create_date: "",
                system: "",
                degree: "",
                function_name: "",
                fault_name: "",
                fault_reason: "",
            },
            searchShow: false,
            infoShow: false,
        });
        const myDataArray = ref([]);
        const formatTime = () => {
            if (event.filterValues.time) {
                const times = event.filterValues.time.split("至");
                if (times.length <= 1) return times[0];
                return `${times[0].trim()},${times[1].trim()}`;
            }
            return null;
        };
        const getDegreeColor = (degree) => {
            switch (degree) {
                case "一般":
                    return "info";
                case "提醒":
                    return "warning";
                case "严重":
                    return "danger";
            }
        };
        watch(
            [
                () => event.filterValues.time,
                () => event.filterValues.degree,
                () => event.filterValues.secondary_class,
                () => event.filterValues.eqname,
                () => event.filterValues.system,
            ],
            (v) => {
                // detail_get_results({
                //   degree: v.value,
                //   time: event.filterValues.time,
                //   type: event.filterValues.Type.value,
                // }).then((res) => {
                //   for (let i in res.data) {
                //     const t = {
                //       text: i,
                //       opened: false,
                //       children: res.data[i].map((item) => {
                //         console.log(
                //             item.id,
                //             "今飞凯达老师讲课老地方随机发徕卡的"
                //         );
                //         return {
                //           text: item.function_name,
                //           id: item.id,
                //           data: item,
                //         };
                //       }),
                //     };
                //     myDataArray.value.push(t);
                //   }
                const time = formatTime();
                const params = {};
                Object.entries(event.filterValues).forEach((item) => {
                    params[item[0]] = item[1]?.value ?? null;
                });
                event.loading = true;
                detail_get_results({ ...params, time })
                    .then((res) => {
                        myDataArray.value = res.data;
                    })
                    .finally(() => {
                        event.loading = false;
                    });
            }
        );

        const siteOptions = ref([
            { value: "1", text: "One" },
            { value: "2", text: "Two" },
            { value: "3", text: "Three" },
        ]);
        const selectedSite = ref(null);

        return {
            // data
            ...toRefs(event),
            myDataArray,
            selectedSite,
            siteOptions,
            getDegreeColor,
            zh,
        };
    },
    methods: {
        handleNewDeviceParams(data) {
            if (!data) return;
            this.selectFaultId = data.id;
            getDiagnosisTraceabilityGetDeviceInfo({
                equsid: data.equsid,
                function_id: data.function_id,
            }).then((res) => {
                // this.device_equids.push(detail.equsid)
                const r = {
                    ...res.data,
                    eqname: data.eqname,
                    equsid: data.equsid,
                    fault_name: data.fault_name,
                    degree: data.degree,
                };
                this.$refs.deviceTableInstance.addDevice(r, true);
            });
        },
    },
    mounted() {
        this.filterValues.time = `${momentJs().format("YYYY-MM-DD")}`;
        getDiagnosisDetailSearchOptions().then((res) => {
            const data = res.data;
            // degree_fields
            // secondary_class_fields
            // system_fields
            // type_fields
            this.filterOptions.Degree = data.degree_fields
                .filter((item) => item.degree)
                .map((item) => ({
                    value: item.degree,
                    label: item.degree,
                }));
            this.filterOptions.Secondary = data.secondary_class_fields
                .filter((item) => item.secondary_class)
                .map((item) => ({
                    value: item.secondary_class,
                    label: item.secondary_class,
                }));
            this.filterOptions.System = data.system_fields
                .filter((item) => item.system)
                .map((item) => ({
                    value: item.system,
                    label: item.system,
                }));
            this.filterOptions.Type = data.type_fields
                .filter((item) => item.type)
                .map((item) => ({
                    value: item.type,
                    label: item.type,
                }));
            this.filterOptions.Eqname = data.eqname_fields
                .filter((item) => {
                    return item && item.eqname;
                })
                .map((item) => {
                    return {
                        value: item.eqname,
                        label: item.eqname,
                    };
                });
        });
        getDiagnosisAllDevices().then((res) => {
            this.allDevices = res.data;
            // {sid: 8306, EQUSID: '12050383000001', eqcode: 'JSF_ZLXT_B2_A_CH1', eqname: '1号离心制冷机', posisid: '200083', …}
            // {sid: 8307, EQUSID: '12050383000002', eqcode: 'JSF_ZLXT_B2_A_CH2', eqname: '2号离心制冷机', posisid: '200083', …}
        });
    },
};
</script>

<style lang="scss">
//@import "../../../../@core/scss/vue/libs/vue-flatpicker";
@import "/src/@core/scss/vue/libs/vue-flatpicker";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.0/font/bootstrap-icons.css");

@import "../../../../../@core/scss/vue/libs/vue-select";

.tree-default ::v-deep .tree-selected {
    // background: #b40a0a !important;
    background: unset !important;
    border: 0;
    box-shadow: none;
}

.tree-default ::v-deep .tree-node {
    min-height: 24px;
    line-height: 24px;
    margin-left: 30px;
    min-width: 300px !important;
}

.custom-row ::v-deep {
    height: 15px !important;
    border: 2px solid red !important;
}

.listGroupSelect {
    .list-group-item {
        border: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        cursor: pointer;

        &:hover {
            transform: translateY(-2px);
            transition: all 0.2s;
        }
    }
}
</style>
