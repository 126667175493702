<template>
  <div>
    <b-card-body v-if="deviceData.fault_name" class="pb-0">
      <b-card-title>
        {{ deviceData.fault_name }}

        <!--        <small class="ml-1">-->
        <!--          <b-icon-clock-fill class="mr-25" />-->
        <!--          2024-04-27 09:24:25</small>-->
      </b-card-title>

      <!-- 月份 -->
      <b-row>
        <b-col cols="10" offset="2" class="d-flex px-2">
          <small v-for="(daysInMonth, index) in daysInMonths" :key="index" :style="{
                        width: calculateMonthWidth(daysInMonth),
                    }">
            {{ getMonthName(index + 1) }}
          </small>
        </b-col>
      </b-row>
      <!-- 持续时间 -->
      <!--      <div v-for="(device, deviceIndex) in devices" :key="deviceIndex">-->
      <div>
        <!--        <div v-if="deviceIndex === 0">-->
        <div>
          <b-row>
            <b-col cols="2">风险持续时间
              <b-badge variant="light-primary" class="ml-1">
                {{
                  mainFault.diagnosis_date.length
                }}
                days
              </b-badge>
            </b-col>
            <b-col cols="10" class="d-flex">
              <div
                  style="background-color: #1890ff1f; border-top-left-radius: 50%; border-bottom-left-radius: 50%; width: 1.45rem;">
              </div>
              <div v-for="(
                                      daysInMonth, monthIndex
                                  ) in daysInMonths" :key="monthIndex" :style="{ flex: 1, display: 'flex' }">
                <div v-for="day in daysInMonth" :key="day" :style="{
                                    flex: 1,
                                    backgroundColor: getBackgroundColor(
                                        day,
                                        monthIndex
                                    ),
                                }"></div>
              </div>
              <div
                  style="background-color: #1890ff1f; border-top-right-radius: 50%; border-bottom-right-radius: 50%; width: 1.45rem;">
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <hr class="offsetMargin mt-2"/>
    </b-card-body>

    <vue-perfect-scrollbar style="height: calc(100vh - 331px)">
      <b-card-body class="py-0">
        <div v-for="(device, deviceIndex) in devices" :key="deviceIndex">
          <hr v-if="deviceIndex !== 0" class="offsetMargin"/>

          <div class="d-flex align-items-center justify-content-between">
            <b-badge pill variant="light-info" class="px-1 py-50">
              <b-icon-gear-fill class="mr-50"/>
              {{ device.deviceName ? device.deviceName : deviceData.device_info.eqname }}
            </b-badge>
            <div>
              <b-button v-if="deviceIndex !== 0" variant="flat-primary" class="btn-icon rounded-circle"
                        size="sm" @click="deleteDevice(deviceIndex)" v-b-tooltip.hover.v-primary title="移除设备">
                <b-icon-trash-fill/>
              </b-button>
            </div>
          </div>

          <!-- 参数列表循环渲染 -->
          <b-row v-for="(parameter, index) in device.parameters" :key="index"
                 class="d-flex align-items-center mb-0">
            <b-col cols="3" class="d-flex align-items-center">
              <v-select v-model="parameter.selectedSite"
                        :options="device.data.params.map(item => ({ value: item.pvvarname, label: item.pvchinaname }))"
                        :clearable="false" @option:selected="
                                    handleChangeDeviceParamsSelected(
                                        device,
                                        $event,
                                        index
                                    )" placeholder="选择参数" class="w-100 my-1 parameterSelect"/>

              <b-button variant="flat-primary" size="sm" class="btn-icon rounded-circle ml-50" @click="
                                removeParameter(index, deviceIndex)
                                " v-b-tooltip.hover.v-primary title="移除参数">
                <b-icon-x-circle-fill/>
              </b-button>
            </b-col>

            <b-col cols="9">
              <div :id="'lineChart_' +
                                device.deviceName +
                                index
                                ">
              </div>
            </b-col>
          </b-row>

          <b-button class="mt-1" variant="flat-primary" @click="addParameter(deviceIndex)">
            <b-icon-plus-circle-fill class="mr-50"/>
            添加参数
          </b-button>
        </div>
      </b-card-body>
    </vue-perfect-scrollbar>

    <b-card-footer v-if="deviceData.fault_name" class="d-flex align-items-center justify-content-between py-50">
      <b-button variant="flat-primary" v-b-modal:add-device-modal :disabled="!devices.length"
                @click="selectDeviceEqusid = null">
        <b-icon-hdd-fill class="mr-50"/>
        添加对比设备
      </b-button>

      <div class="d-flex align-items-center">
        <b-form-checkbox @change="handleChangeAreaXDisplayState" checked="false" switch>
                    <span class="switch-icon-left">
                        <b-icon-sliders/>
                    </span>
          <span class="switch-icon-right">
                        <b-icon-sliders/>
                    </span>
        </b-form-checkbox>

        <b-button-group class="ml-1" style="flex: none;">
          <b-button @click="setSearchTime('today')" variant="flat-primary">
            今天
          </b-button>
          <b-button @click="setSearchTime('week')" variant="flat-primary">
            近7天
          </b-button>
          <b-button @click="setSearchTime('month')" variant="flat-primary">
            近30天
          </b-button>
        </b-button-group>
        <flat-pickr v-model="searchTimeRange" placeholder="选择时间" :config="{
                    mode: 'range',
                    locale: $i18n.locale === 'cn' ? 'zh' : 'en',
                    onChange: updateParamsCharts
                }" class="form-control ml-1" style="width: 210px"/>
      </div>
    </b-card-footer>

    <div v-if="!devices.length" style="
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
              " class="text-center">
      <div>
        <b-icon-arrow-left-circle-fill class="mr-25"></b-icon-arrow-left-circle-fill>
        选择需要查看的风险事件
      </div>
      <b-icon-envelope-fill scale="5" class="my-3"></b-icon-envelope-fill>
      <div class="text-muted">Nothing is selected</div>
    </div>
    <b-modal id="add-device-modal" centered title="添加设备" @ok="handleAddDevice" cancel-title="取消" ok-title="添加">
      <v-select v-model="selectDeviceEqusid" id="inline-form-custom-select-pref" placeholder="请选择设备"
                :options="allDevices.map(item => ({ value: item.EQUSID, label: item.eqname }))" :clearable="false">
      </v-select>
    </b-modal>
  </div>
</template>
<script>
import {onMounted, ref, watch, computed, nextTick} from "@vue/composition-api";
import {Line, Area} from "@antv/g2plot";
import {BAlert} from "bootstrap-vue";
import {Chart, ChartEvent} from "@antv/g2";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {showToast} from "@/libs/utils/showToast";
import {momentJs} from "@/libs/utils/moment";
import {
  getDiagnosisTraceabilityGetDeviceDiagnosisForParams,
  getDiagnosisTraceabilityGetDeviceInfo,
} from "@/api/diagnosis";

import flatPickr from "vue-flatpickr-component";
import zh from "flatpickr/dist/l10n/zh.js";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  name: "DeviceTable",
  props: {
    allDevices: {
      type: Array,
    },
  },
  components: {
    BAlert,
    vSelect,
    VuePerfectScrollbar,
    flatPickr,
  },

  setup() {
    const getBackgroundColor = (day, monthIndex) => {
      const currentStrDate = `${monthIndex},${day}`;

      if (mainFault.value.diagnosis_date.includes(currentStrDate)) {
        return "#1890ffaa";
      } else {
        return "#1890ff1f";
      }
    };

    const selectDeviceEqusid = ref(null);

    const daysInMonths = ref([
      31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
    ]); // 每个月的天数

    const deviceData = ref({});

    const calculateMonthWidth = (daysInMonth) => {
      // 计算每个月份的宽度比例
      const totalCols = 10; // 固定总宽度为10
      return `${(daysInMonth / 365) * 100}%`;
    };
    const searchTimeRange = ref(`${momentJs().format('YYYY-MM-DD')}`)
    const mainFault = ref({
          diagnosis_date: []
        }
    )
    const getMonthName = (month) => {
      // 获取月份名称
      const monthNames = [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ];
      return monthNames[month - 1];
    };

    const handleAddDevice = () => {
      if (!selectDeviceEqusid.value) {
        return showToast(null, "提示", "未选择设备");
      }
      getDiagnosisTraceabilityGetDeviceInfo({
        equsid: selectDeviceEqusid.value.value,
      }).then((res) => {
        addDevice({
          ...res.data,
          eqname: res.data.device_info.eqname,
          equsid: res.data.device_info.EQUSID,
        });
      });
    };

    const parameters = ref([]); // 存储参数信息的数组

    // const addParameter = () => {
    //   parameters.value.push({
    //     selectedSite: null,
    //     rendered: false,
    //     // 其他参数属性...
    //   });
    // };

    const addParameter = (deviceIndex) => {
      console.log("设备index", deviceIndex);
      const selectedDevice = devices.value[deviceIndex];

      // 在选定的设备上添加参数
      selectedDevice.parameters.push({
        selectedSite: null,
        rendered: false,
        // 其他参数属性...
      });
    };

    const removeParameter = (index, deviceIndex) => {
      // parameters.value.splice(index, 1);
      const selectedDevice = devices.value[deviceIndex];
      selectedDevice.parameters.splice(index, 1);
    };

    const selectedSite = ref(null);
    const siteOptions = ref([
      {value: "1", text: "One"},
      {value: "2", text: "Two"},
      {value: "3", text: "Three"},
    ]);

    const data = [
      {
        Date: "2014-01-01 12:00:03",
        scales: 1750,
      },
      {
        Date: "2014-02-01 12:00:03",
        scales: 1602,
      },
      {
        Date: "2014-03-01 12:00:03",
        scales: 1834,
      },
      {
        Date: "2014-04-01 12:00:03",
        scales: 1722,
      },
      {
        Date: "2014-05-01 12:00:03",
        scales: 1430,
      },
      {
        Date: "2014-06-01 12:00:03",
        scales: 1280,
      },
      {
        Date: "2014-07-01 12:00:03",
        scales: 1367,
      },
      {
        Date: "2014-08-01 12:00:03",
        scales: 1155,
      },
      {
        Date: "2014-09-01 12:00:03",
        scales: 1289,
      },
      {
        Date: "2014-10-01 12:00:03",
        scales: 1104,
      },
      {
        Date: "2014-11-01 12:00:03",
        scales: 1246,
      },
      {
        Date: "2014-12-01 12:00:03",
        scales: 1098,
      },
      {
        Date: "2015-01-02 12:00:05",
        scales: 1189,
      },
      {
        Date: "2015-02-02 12:00:05",
        scales: 1276,
      },
      {
        Date: "2015-03-02 12:00:05",
        scales: 1033,
      },
      {
        Date: "2015-04-02 12:00:05",
        scales: 956,
      },
      {
        Date: "2015-05-02 12:00:05",
        scales: 845,
      },
      {
        Date: "2015-06-02 12:00:05",
        scales: 1089,
      },
      {
        Date: "2015-07-02 12:00:05",
        scales: 944,
      },
      {
        Date: "2015-08-02 12:00:05",
        scales: 1043,
      },
      {
        Date: "2015-09-02 12:00:05",
        scales: 893,
      },
      {
        Date: "2015-10-02 12:00:05",
        scales: 840,
      },
      {
        Date: "2015-11-02 12:00:05",
        scales: 934,
      },
      {
        Date: "2015-12-02 12:00:05",
        scales: 810,
      },
      {
        Date: "2016-01-04 14:03:02",
        scales: 782,
      },
      {
        Date: "2016-02-04 14:03:02",
        scales: 1089,
      },
      {
        Date: "2016-03-04 14:03:02",
        scales: 745,
      },
      {
        Date: "2016-04-04 14:03:02",
        scales: 680,
      },
      {
        Date: "2016-05-04 14:03:02",
        scales: 802,
      },
      {
        Date: "2016-06-04 14:03:02",
        scales: 697,
      },
      {
        Date: "2016-07-04 14:03:02",
        scales: 583,
      },
      {
        Date: "2016-08-04 14:03:02",
        scales: 456,
      },
      {
        Date: "2016-09-04 14:03:02",
        scales: 524,
      },
      {
        Date: "2016-10-04 14:03:02",
        scales: 398,
      },
      {
        Date: "2016-11-04 14:03:02",
        scales: 278,
      },
      {
        Date: "2016-12-04 14:03:02",
        scales: 195,
      },
      {
        Date: "2017-01-21 12:03:15",
        scales: 145,
      },
      {
        Date: "2017-02-11 11:14:43",
        scales: 207,
      },
    ];


    const InitLinePlot = (containerId, data) => {
      // 注册主题
      const chart = new Area(containerId, {
        data,
        // xField: "ReadingDt",
        // yField: "EnvDataH",
        xField: "time",
        yField: "value",
        height: 150,
        xAxis: {
          visible: true,
          grid: null,
        },
        yAxis: {
          grid: null,
        },

        slider: {
          visible: false,
          start: 0,
          end: 1,
          textStyle: {
            fill: "#eeeeee",
            fillOpacity: 1,
            shadowColor: "black",
            shadowBlur: 1,
          },
          trendCfg: {
            lineStyle: {
              fillOpacity: 1,
            },
          },
        },
        animate: true,
        animation: {
          appear: {
            animation: "wave-in",
            duration: 10000,
          },
        },
      });
      chart.render();
      return chart;
    };

    const devices = ref([]);
    // const addDevice = () => {
    //   const newDevice = {
    //     deviceName: `设备${devices.value.length + 1}`,
    //   };

    //   devices.value.push(newDevice);
    // };

    const reset = () => {
      for (let device of devices.value) {
        for (let deviceChart of Object.values(device.chartInstance)) {
          deviceChart.destroy();
        }
      }
      devices.value = [];
    };

    const addDevice = (data, isMain = false) => {
      if (isMain) {
        reset();
        mainFault.value = {
          diagnosis_date: data.diagnosis_date ? Array.from(
              new Set(
                  data.diagnosis_date.map((x) => {
                    // console.log(
                    //     x,
                    //     x.create_date,
                    //     "create_datecreate_datecreate_date"
                    // );
                    const d = new Date(x.create_date);
                    return `${d.getMonth()},${d.getDate()}`;
                  })
              )
          ) : []
        }
        deviceData.value = {...data};
      }
      if (devices.value.map((x) => x.data.equsid).includes(data.equsid)) {
        return showToast(null, "提示", "设备已存在溯源列表中");
      }
      const newDevice = {
        deviceName: data.eqname,
        parameters: [], // 添加这一行，初始化参数列表为空数组
        chartInstance: {},
        data,
        cache: {
          diagnosis_data: data.diagnosis_date ? Array.from(
              new Set(
                  data.diagnosis_date.map((x) => {
                    // console.log(
                    //     x,
                    //     x.create_date,
                    //     "create_datecreate_datecreate_date"
                    // );
                    const d = new Date(x.create_date);
                    return `${d.getMonth()},${d.getDate()}`;
                  })
              )
          ) : [],
        },
      };
      devices.value.push(newDevice);
      console.log(devices.value)
    };

    const deleteDevice = (index) => {
      devices.value.splice(index, 1);
    };


    const selectedYear = ref(new Date().getFullYear());

    const yearOptions = computed(() => {
      const currentYear = new Date().getFullYear();
      const minYear = currentYear - 10; // 最小年份往前推 10 年
      const options = [];

      for (let year = currentYear; year >= minYear; year--) {
        // options.push({ text: year.toString(), value: year });
        options.push({text: `${year}年`, value: year});
      }

      return options;
    });

    const handleYearSelect = (year) => {
      console.log("选择年份", year, selectedYear.value);
      selectedYear.value = year.text;
      // 在这里可以执行其他操作，例如根据选择的年份更新页面内容
    };

    const formatTime = () => {
      if (searchTimeRange.value) {
        const times = searchTimeRange.value.split("至");
        if (times.length <= 1) return times[0];
        return `${times[0].trim()},${times[1].trim()}`;
      }
      return null;
    };

    const handleChangeAreaXDisplayState = (v) => {
      for (let device of devices.value) {
        for (let chart of Object.values(device.chartInstance)) {
          chart.update({
            slider: {
              visible: v
            }
          })
        }
      }
    }

    const handleChangeDeviceParamsSelected = (
        device,
        pointervarname,
        paramsIndex
    ) => {
      if (!pointervarname) return;
      getDiagnosisTraceabilityGetDeviceDiagnosisForParams({
        equsid: device.data.equsid,
        pointer: pointervarname.value,
        times: formatTime()
      }).then((res) => {
        const containerId =
            "lineChart_" + device.deviceName + paramsIndex;
        if (!res.data.length) {
          device.parameters[paramsIndex].selectedSite = null;
          return showToast(null, "提示", "该节点不存在数据");
        }
        if (
            device.parameters[paramsIndex].selectedSite !== null &&
            device.parameters[paramsIndex].rendered === false
        ) {
          device.chartInstance[containerId] = InitLinePlot(
              containerId,
              res.data
          );
          device.parameters[paramsIndex].rendered = true;
        } else {
          const chart = device.chartInstance[containerId];
          if (!chart) return;
          chart.changeData(res.data);
          // chart.render();
        }
      });
    };

    const setSearchTime = (flag) => {
      switch (flag) {
        case 'today':
          searchTimeRange.value = `${momentJs().format('YYYY-MM-DD')}`
          break
        case 'week':
          searchTimeRange.value = `${momentJs().subtract(7, 'days').format('YYYY-MM-DD')} 至 ${momentJs().format('YYYY-MM-DD')}`
          break
        case 'month':
          searchTimeRange.value = `${momentJs().subtract(30, 'days').format('YYYY-MM-DD')} 至 ${momentJs().format('YYYY-MM-DD')}`
          break
      }
      updateParamsCharts()
    }

    const updateParamsCharts = () => {
      // v-model="parameter.selectedSite"
      for (let device of devices.value) {
        let index = 0
        for (let parameter of device.parameters) {
          handleChangeDeviceParamsSelected(device, parameter.selectedSite, index)
          index++
        }
      }
    }

    return {
      text: "",
      getBackgroundColor,
      daysInMonths,
      calculateMonthWidth,
      getMonthName,
      parameters,
      addParameter,
      removeParameter,
      selectedSite,
      siteOptions,
      addDevice,
      deleteDevice,
      devices,
      selectedYear,
      yearOptions,
      handleYearSelect,
      handleChangeDeviceParamsSelected,
      handleChangeAreaXDisplayState,
      deviceData,
      selectDeviceEqusid,
      handleAddDevice,
      searchTimeRange,
      setSearchTime,
      updateParamsCharts,
      zh,
      mainFault
    };
  },
};
</script>

<style scoped lang="scss">
.year-picker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150px;
  margin: 0 auto;
}

.offsetMargin {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

::v-deep .parameterSelect {
  /* .vs__dropdown-toggle {
  .vs__selected-options {
      .vs__selected {
          overflow: hidden !important;
          white-space: nowrap !important;
          text-overflow: ellipsis !important;
      }

      .vs__search {
          overflow: hidden !important;
          white-space: nowrap !important;
          text-overflow: ellipsis !important;
      }
  }
} */

  .vs__dropdown-menu {
    overflow: hidden;

    li {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

::v-deep .btnDropdown {
  .btn {
    padding: 0.5rem;
    border-radius: 50%;
  }
}

@import "/src/@core/scss/vue/libs/vue-flatpicker.scss";
</style>
